#politica-de-privacidade,
#cookies header {
  visibility: visible;
  opacity: 1;
}

#politica-de-privacidade h3 {
  font-size: 2rem;
  font-weight: 600;
}

#politica-de-privacidade .texto-politicas > p {
  text-align: justify;
}
