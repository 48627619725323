@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap");
html {
  scroll-behavior: smooth;
}

body,
.body {
  overflow-x: hidden !important;
  background-color: #f6f6f6;
}

:root {
  --cor-fazenda: #00442e;
  --cor-fazenda-secondary: #928a3b;
  --cor-fazenda-rgba: 0 68 46;
  --cor-secondaria: #808080;
}

.cor-fazenda {
  color: var(--cor-fazenda);
}

.cor-secondaria {
  color: var(--cor-secondaria);
}

.bg-fazenda {
  background-color: var(--cor-fazenda);
}

.bg-pastel {
  background-color: #f6f6f6;
}

.bg-textura-01 {
  background-image: url(./img/bgs/white-fabric-texture.jpg);
}

.fontAbhaya {
  font-family: "Abhaya Libre", serif !important;
}

.offcanvas-end {
  width: 100% !important;
  border-left: unset;
}

#menu_fazenda_titulo .btn-close {
  width: 50px;
  height: 50px;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
    center/1.2em auto no-repeat;
  opacity: 1;
}

.btn.btn-fazenda {
  color: #fff;
  background-color: var(--cor-fazenda);
  border-color: var(--cor-fazenda);
  font-weight: bold;
}

.btn.btn-fazenda:hover {
  color: #fff;
  background-color: var(--cor-fazenda);
  border-color: var(--cor-fazenda);
  font-weight: bold;
}

.btn.btn-fazenda {
  color: #fff;
  background-color: var(--cor-fazenda);
  border-color: var(--cor-fazenda);
  font-weight: bold;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.btn.btn-fazenda:disabled {
  color: #fff;
  background-color: var(--cor-fazenda);
  border-color: var(--cor-fazenda);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.btn.btn-fazenda:focus {
  color: #fff;
  background-color: var(--cor-fazenda);
  border-color: var(--cor-fazenda);
  font-weight: bold;
}

.grecaptcha-badge {
  display: none;
}

@media (max-width: 320px) {
  #menu_fazenda_titulo .btn-close {
    width: 25px;
    height: 25px;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e")
      center/1.2em auto no-repeat;
  }
}
