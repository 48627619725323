#sobre {
  background-image: url(./img/11.webp);
  min-height: 500px;
  background-size: cover;
  background-position: center;
}

#fundo_branco_texto {
  background-image: url(./img/FundoTextoSobre.webp);
  height: 100%;
  background-repeat: no-repeat;
  background-position-y: 80px;
  background-size: contain;
}

#fundo_branco_texto .content {
  padding-left: 70px;
}

.sobre-position {
  top: -80px;
  z-index: 1;
}

.conteudo {
  position: relative;
  z-index: 1;
  margin-top: -90px;
}

.titulo-sobre {
  color: var(--cor-fazenda);
  font-size: calc(1.375rem + 1.1vw);
}

.sobre-a-fazenda p {
  font-size: 18px;
  text-align: justify;
}

.sobre-a-fazenda .content {
  margin-bottom: 30px;
  padding-right: 90px;
}

@media (min-width: 1440px) {
  .sobre-a-fazenda .content {
    margin-bottom: 30px;
    padding-right: 170px;
  }
}

@media (max-width: 768px) {
  #fundo_branco_texto {
    background-size: cover;
  }
  #fundo_branco_texto .content {
    padding-left: 70px;
    padding-right: 40px;
  }
  .titulo-sobre {
    font-size: calc(1.375rem + 2vw);
  }
  .conteudo {
    margin-top: 0px;
  }
}

@media (max-width: 414px) {
  .titulo-sobre {
    font-size: calc(1.375rem + 0.8vw);
  }
}

@media (max-width: 375px) {
  .titulo-sobre {
    font-size: calc(1.375rem + 0vw);
  }
  .sobre-a-fazenda p {
    font-size: 14px;
  }
  #texto-sobre-a-fazenda p {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .sobre-a-fazenda a.btn.btn-fazenda {
    font-size: 14px;
  }
}

@media (max-width: 374px) {
  .titulo-sobre {
    font-size: calc(1.375rem - 1.4vw);
  }
  .sobre-a-fazenda p {
    font-size: 10px;
  }
  #texto-sobre-a-fazenda p {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .sobre-a-fazenda a.btn.btn-fazenda {
    font-size: 12px;
  }
}
