#atividades .card-img,
#atividades .card-img-bottom,
#atividades .card-img-top {
    width: 100%;
    object-fit: cover;
    height: 22rem;
    object-position: center;
}

#atividades .swiper-wrapper {
    padding-bottom: 50px;
}

#atividades .card {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
}

#atividades .mySwiper {
    padding: 20px 0;
}

#atividades .card:hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}

#atividades .card-body {
    position: relative;
    padding: 40px 1rem;
    text-align: center;
}

#atividades .card-title {
    color: #471f07;
    font-weight: bold;
}

#atividades .swiper-pagination-bullet {
    width: var( --swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 14px));
    height: var( --swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 14px));
}

#atividades .swiper-pagination-bullet-active {
    background: #5a3722;
}

.card-slide .slideCard {
    height: calc(100vh - 120px);
}

.card-slide .slideCard img {
    width: 100%;
    height: calc(100vh - 120px);
    object-fit: contain;
    object-position: center;
}

@media (max-width: 1366px) {
    #atividades .h5 {
        font-size: 1.1rem;
    }
}

@media (max-width: 820px) {
    #atividades .card-img,
    #atividades .card-img-bottom,
    #atividades .card-img-top {
        height: auto;
    }
    #atividades .card:hover {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }
}

@media (max-width: 414px) {
    #atividades .card-img,
    #atividades .card-img-bottom,
    #atividades .card-img-top {
        height: 18rem;
    }
    #atividades .card {
        width: 18rem;
    }
    #atividades .card-body {
        position: relative;
        padding: 1rem;
        text-align: center;
    }
}

@media (max-width: 393px) {
    #atividades .card-img,
    #atividades .card-img-bottom,
    #atividades .card-img-top {
        height: 16rem;
    }
    #atividades .card {
        width: 16rem;
    }
    #atividades .card-body {
        position: relative;
        padding: 1rem;
        text-align: center;
    }
}

@media (max-width: 375px) {
    #atividades .card-img,
    #atividades .card-img-bottom,
    #atividades .card-img-top {
        height: 15rem;
    }
    #atividades .card {
        width: 15rem;
    }
}

@media (min-width: 1024px) {
    #atividades .card {
        width: 16rem;
    }
    #atividades .card-img,
    #atividades .card-img-bottom,
    #atividades .card-img-top {
        height: 16rem;
    }
}

@media (min-width: 1280px) {
    #atividades .card {
        width: 19rem;
    }
    #atividades .card-img,
    #atividades .card-img-bottom,
    #atividades .card-img-top {
        height: 19rem;
    }
}

@media (min-width: 1440px) {
    #atividades .card {
        width: 22rem;
    }
    #atividades .card-img,
    #atividades .card-img-bottom,
    #atividades .card-img-top {
        height: auto;
    }
}