#slidePrincipal .swiper {
    width: 100%;
    height: 100vh;
}

#slidePrincipal .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

#slidePrincipal .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#slidePrincipal .swiper {
    margin-left: auto;
    margin-right: auto;
}

.btn-white {
    color: #000;
    background-color: #fff !important;
    border-color: #fff !important;
}

.btn-check:focus+.btn-white.btn-light,
.btn-white.btn-light:focus {
    box-shadow: unset !important;
}

.botao_reserve_agora {
    bottom: 100px;
    z-index: 1;
}

.borda-SlidePrincial {
    border-bottom: 7px solid var(--cor-fazenda-secondary);
}

.bloco-slide {
    width: 150px;
    height: 150px;
    background-color: #000;
}

#slidePrincipal img, #slidePrincipal picture {
    transition: 3s all;
    -webkit-transition: 3s all;
    -moz-transition: 3s all;
    -ms-transition: 3s all;
    -o-transition: 3s all;
}

.zoom-img {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}

#slidePrincipal .swiper-button-prev, #slidePrincipal .swiper-button-next {
    color: #fff;
}