.slideSumario .slick-slider img {
  width: 100%;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}

.slideSumario .slick-prev:before,
.slideSumario .slick-next:before {
  color: var(--cor-fazenda) !important;
  font-size: 40px;
}
.slideSumario .slick-prev {
  left: 0 !important;
  z-index: 1;
}

.slideSumario .slick-next {
  right: 0px !important;
  z-index: 1;
}

.slideSumario .slick-prev,
.slideSumario .slick-next {
  width: 40px !important;
  height: 40px !important;
  font-size: 40px !important;
}

.responsive-table-info tr th {
  width: 20%;
  display: table-cell !important;
}

@media (max-width: 768px) {
  .card-footer button a {
    font-size: 12px;
  }
}
