.site-header.header-over {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--cor-fazenda);
  background: linear-gradient(
    to bottom,
    rgb(var(--cor-fazenda-rgba) / 60%),
    rgb(var(--cor-fazenda-rgba) / 50%),
    rgb(var(--cor-fazenda-rgba) / 40%),
    #ffffff00
  );
  z-index: 10;
  width: 100%;
}

#politica-de-privacidade .site-header.header-over,
#cookies .site-header.header-over {
  background: var(--cor-fazenda) !important;
  position: unset !important;
}

#politica-de-privacidade .site-header .header-bottom,
#cookies .site-header .header-bottom {
  min-height: 90px;
}

.site-header .header-top {
  padding-top: 47px;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
}

.site-header .header-bottom {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  min-height: 110px;
}

.site-header .header-top .header-contact ul li {
  float: left;
  line-height: 20px;
  margin-right: 20px;
  text-transform: uppercase;
}

.site-header .header-top a {
  color: #d1c2ac;
}

.site-header .header-top .header-contact ul li a {
  display: block;
  height: 20px;
  line-height: 20px;
}

header ul li {
  list-style-type: none;
}

.site-header .header-bottom .header-logo {
  position: absolute;
  z-index: 1001;
  top: -100px;
  left: 50%;
  width: 180px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.site-header .header-bottom .header-logo img {
  width: 100%;
}

.header-contact > ul > li {
  text-align: center !important;
}

header .navbar-light .navbar-text,
header .navbar-light a svg,
header .navbar-light .navbar-nav .nav-link,
header .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 1) !important;
}

header .navbar-light a svg,
header .navbar-light a img {
  color: rgb(255 255 255);
  width: 18px;
  height: 18px;
}

header .header-bottom .me-auto.navbar-nav:after {
  content: "";
  height: 2px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 50px;
}

header .header-bottom .ms-auto.navbar-nav:after {
  content: "";
  height: 2px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  top: 50px;
}

header .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  width: 50px;
}

header .navbar-light .navbar-toggler {
  margin-left: auto;
}

header .navbar-light .navbar-toggler {
  border-color: transparent;
}

@media (max-width: 1024px) and (max-height: 768px) {
  .site-header .header-top {
    font-size: 14px;
  }
  .site-header .header-bottom {
    font-size: 14px;
  }
}

@media (max-width: 840px) {
  .site-header .header-top {
    padding-top: 0;
    font-size: 20px;
  }
  header .navbar-light a svg,
  header .navbar-light a img {
    color: rgb(255 255 255);
    width: 15px;
    height: 15px;
  }
  .site-header .header-bottom .header-logo {
    top: -50px;
  }
  header .navbar-light .navbar-toggler-icon {
    width: 25px;
  }
  #sobre {
    background-position: -232px !important;
  }
  .bg-menu {
    background: var(--cor-fazenda);
  }
  #menu_fazenda_titulo {
    position: relative;
  }
  #menu_fazenda_titulo .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  #menu_fazenda .navbar-nav {
    padding-left: 40px;
  }
  #menu_fazenda > *,
  #menu_fazenda a {
    font-size: 18px !important;
    color: #fff;
    font-weight: 600;
  }
  #menu_fazenda a::before {
    content: "\2022";
    font-size: 14px;
    padding-right: 10px;
    font-weight: bold;
  }
  .offcanvas-footer {
    padding: 1rem 1rem;
  }
}

@media (max-width: 512px) {
  .site-header .header-top {
    font-size: 13px;
  }
  .site-header .header-bottom .header-logo {
    top: 0px;
  }
  #politica-de-privacidade .site-header .header-bottom,
  #cookies .site-header .header-bottom {
    min-height: 140px;
  }
  .site-header .header-bottom {
    min-height: 100px;
  }
  .site-header .header-bottom .header-logo img {
    width: 75%;
  }
}

@media (max-width: 413px) {
  .site-header .header-top {
    font-size: 8px;
  }
  .site-header .header-bottom .header-logo {
    top: 0px;
  }
}

@media (max-width: 280px) {
  .site-header .header-top {
    font-size: 6px;
  }
}

@media (min-width: 1024px) {
  header .header-bottom .me-auto.navbar-nav:after {
    content: "";
    width: 45vh;
    left: -2vh;
  }
  header .header-bottom .ms-auto.navbar-nav:after {
    content: "";
    width: 45vh;
    right: -2vh;
  }
}

@media (min-width: 1366px) {
  header .header-bottom .me-auto.navbar-nav:after {
    content: "";
    width: 60vh;
    left: -10vh;
  }
  header .header-bottom .ms-auto.navbar-nav:after {
    content: "";
    width: 60vh;
    right: -10vh;
  }
}

@media (min-width: 1440px) {
  header .header-bottom .me-auto.navbar-nav:after {
    content: "";
    width: 60vh;
    left: -5vh;
  }
  header .header-bottom .ms-auto.navbar-nav:after {
    content: "";
    width: 60vh;
    right: -5vh;
  }
}

@media (min-width: 1680px) {
  header .header-bottom .me-auto.navbar-nav:after {
    content: "";
    width: 60vh;
    left: -15vh;
  }
  header .header-bottom .ms-auto.navbar-nav:after {
    content: "";
    width: 60vh;
    right: -15vh;
  }
}

@media (min-width: 1920px) {
  header .header-bottom .me-auto.navbar-nav:after {
    content: "";
    width: 65vh;
    left: -20vh;
  }
  header .header-bottom .ms-auto.navbar-nav:after {
    content: "";
    width: 65vh;
    right: -20vh;
  }
}

@media (min-width: 1921px) {
  header .header-bottom .me-auto.navbar-nav:after {
    content: "";
    width: 90vh;
    left: -40vh;
  }
  header .header-bottom .ms-auto.navbar-nav:after {
    content: "";
    width: 90vh;
    right: -40vh;
  }
}

@media (max-width: 1180px) and (orientation: landscape) {
  .site-header .header-top {
    font-size: 12px;
  }
  .site-header .header-bottom {
    font-size: 12px;
  }
  header .header-bottom .me-auto.navbar-nav:after {
    content: "";
    width: 50vh;
    left: -10vh;
  }
  header .header-bottom .ms-auto.navbar-nav:after {
    content: "";
    width: 50vh;
    right: -10vh;
  }
}
