#Footer {
    min-height: 40vh;
    background-image: url(./img/senepol_9_percent_opacity.svg);
    background-repeat: no-repeat;
    background-position: center right;
}

#Footer .footer-bg {
    height: 100%;
    width: 100%;
}

#Footer .container {
    z-index: 1;
}

#Footer .navbar-light .navbar-text,
#Footer .navbar-light a svg,
#Footer .navbar-light .navbar-nav .nav-link,
#Footer .navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 1) !important;
}

#Footer .navbar-light a svg,
#Footer .navbar-light a img {
    color: rgb(255 255 255);
    width: 25px;
    height: 25px;
}

#Footer .border-footer {
    border-bottom: 2px solid white;
}

#Footer .info>p {
    margin: 0;
}

@media (min-width: 1024px) {
    #Footer {
        min-height: 40vh;
        background-size: 60%;
    }
}

@media (min-width: 1920px) {
    #Footer {
        background-size: 30%;
    }
}

@media (max-width: 820px) {
    #Footer {
        background-size: 85%;
    }
}

@media (max-width: 414px) {
    #Footer p {
        font-size: 12px;
    }
    #Footer .info p {
        font-size: 14px;
    }
    #Footer .navbar-light a svg,
    #Footer .navbar-light a img {
        color: rgb(255 255 255);
        width: 18px;
        height: 18px;
    }
    #Footer .card-footer p {
        font-size: 11px;
    }
    #Footer {
        background-size: cover;
        background-position-x: -130px;
        background-position-y: 0;
    }
}

@media (max-width: 360px) {
    #Footer .card-footer p {
        font-size: 10px;
    }
}

@media (max-width: 320px) {
    #Footer .card-footer p {
        font-size: 9px;
    }
}